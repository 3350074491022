const DEFAULT_CATEGORY = 'USER_ACTION_PORTAL';

function trackCall (func, event) {
    return function (...args) {
        const result = func.apply(this, args);
        sentAnalyticsEvent(event, { args, result });
    };
}

function sentAnalyticsEvent (event, context) {
    if (!event.action || !event.label) return;

    const data = {
        event_category: event.category !== undefined ? event.category : DEFAULT_CATEGORY,
        event_action: event.action,
        event_label: event.label !== undefined ? event.label : '',
        vss_user_group: window.Settings.user.ga_group
    };

    if (event.value !== undefined) {
        data.value = parseValue(event.value, context);
    }

    gtag('event', 'event', data);
}

function parseValue (value, context) {
    if ($.isFunction(value)) {
        return value.call(this, context.args, context.result);
    } else if (value === (parseInt(value, 10) + '')) return parseInt(value, 10);
    else if (value === true || value === 'true') return 1;
    else if (value === false || value === 'false') return 0;
    else if ($.type(value) === 'string') {
        if (value.startsWith('event.') && context.event) {
            return context.event.target[value.replace(/^event\./, '')];
        } else {
            return value;
        }
    } else throw new TypeError('eventValue is not valid');
}

/* Some HTML elements (ex: <button />) ignore custom HTML attributes
unless they are prefixed with "data-" */
export const sendGA = event => {
    const action = event.target.getAttribute('ga-action') || event.target.getAttribute('data-ga-action');
    const label = event.target.getAttribute('ga-label') || event.target.getAttribute('data-ga-label');
    action && label && sentAnalyticsEvent({ action, label }, { event });
};

const initAnalytics = (function () {
    let initialized = false;

    function _ () {
        if (initialized) return;
        document.addEventListener('click', sendGA);
        initialized = true;
    }

    return _;
})();

initAnalytics();

export {
    trackCall,
    sentAnalyticsEvent,
    parseValue
};
