import { AssetId } from '../asset/asset';

const homePushNotifierHandlersMixin = {
    homePushNotificationHandlers: {
        folder: {
            create (event, assetId, { data }) {
                this.addOrUpdateAsset(assetId, data, true);
            },
            restore (event, assetId, { data }) {
                this.addOrUpdateAsset(assetId, data, true);
            },
            delete: function (event, assetId, { data }) {
                this.removeAsset(assetId);
            }
        },
        file: {
            create (event, assetId, { data }) {
                this.addOrUpdateAsset(assetId, data);
            },
            new_thumbnail (event, assetId, { thumbnail_url }) {
                this.setThumbnailUrl(assetId, thumbnail_url);
            },
            new_version (event, assetId, { data }) {
                const asset = this.assets.find(a => a.owner === assetId.owner && a.prefix === assetId.prefix);
                if (!asset) {
                    this.addOrUpdateAsset(assetId, data);
                } else {
                    this.addOrUpdateAsset(assetId, {
                        ...asset.data,
                        ...data
                    });
                }
            },
            delete: function (event, assetId) {
                this.removeAsset(assetId);
            },
            move: function (event, assetId, data) {
                const newAssetId = AssetId.fromParams(data.dest_asset, true);
                this.renameAsset(assetId, newAssetId, data.data);
            },
            job_completed (event, assetId, { thumbnail_url, results }) {
                const generatedPdf = results[0].data.file_type === 'PDF';
                thumbnail_url && !generatedPdf && this.setThumbnailUrl(assetId, thumbnail_url);
                results && results.forEach(({ asset, thumbnail_url, data }) => {
                    const assetId = AssetId.fromParams(asset);
                    if (this.storageType() === assetId.storageType &&
                            this.currentFolder() === assetId.folder) {
                        this.addOrUpdateAsset(assetId, data);
                        thumbnail_url && this.setThumbnailUrl(assetId, thumbnail_url);
                    }
                });
            }
        }
    }
};

export {
    homePushNotifierHandlersMixin
};
