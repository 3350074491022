import { forToolbar, parseActionMap } from '../utils/toolbar';
import { Dialog } from '../base/dialog';
import { toast } from '../toast';
import '../share/qr-code.vm';
import '../share/vr-code.vm';

import EmptyTrashDialog from '../dialog/react-dialogs/empty-trash-dialog';
import assetApi from '../asset/api';

const GLOBAL_TOOLS_TEMPLATE = [
    ['search'],
    ['upload', 'createFolder', 'createTask', 'createPresentation'],
    ['listView', 'thumbView', 'openUrlDialog', 'finalizeSession', 'addFilesToSession', 'openSettings', 'startPreview', 'toggleLibrary'],
    ['startPresentation', 'downloadPresentation', 'embedCode', 'shareLink', 'qrCode', 'showInNomad']
];

const funcFromBool = function (val) {
    return typeof val === 'function'
        ? val
        : () => val;
};

class ActionToolbarViewModel {
    constructor (ctx = {}) {
        this.view = ko.observable({
            toolbarActions: {},
            items: null
        });
        this.items = ko.pureComputed(() => this.view().items);
        this.breadcrumbs = ko.pureComputed(() => this.view().breadcrumbs || []);
        this.commonActions = {
            listView: {
                action: this.itemsDo('toggleItemStyle'),
                properties: {
                    isAllowed: ko.computed(() => this.items() && !this.items().isListView()),
                    title: gettext('List view'),
                    icon: 'icon-list-view'
                }
            },
            thumbView: {
                action: this.itemsDo('toggleItemStyle'),
                properties: {
                    isAllowed: ko.computed(() => this.items() && this.items().isListView()),
                    title: gettext('Thumbnail view'),
                    icon: 'icon-grid'
                }
            },
            search: {
                action: this.toggleMobileSearchWidget,
                properties: {
                    isAllowed: true,
                    title: gettext('Search'),
                    icon: 'icon-search',
                    isSearch: true
                }
            },
            qrCode: {
                action: () => {
                    return Dialog.open({
                        component: 'dialog-qr-code',
                        ctx: { ctx: { url: window.location.href } }
                    }).result.then(() => {});
                },
                properties: {
                    multiple: false,
                    isAllowed: true,
                    title: gettext('QR code'),
                    icon: 'icon-qr-code',
                    GALabel: 'Click_QRCode'
                }
            }
        };
        this.actions = ko.pureComputed(() => {
            const actions = ko.isObservable(this.view().toolbarActions)
                ? this.view().toolbarActions()
                : this.view().toolbarActions;
            Object.keys(actions).forEach(action => {
                if (action === 'toggleListThumbs') {
                    actions.listView = this.commonActions.listView;
                    actions.thumbView = this.commonActions.thumbView;
                } else if (typeof actions[action] === 'boolean' && !!actions[action]) {
                    actions[action] = this.commonActions[action];
                }
            });
            return actions;
        });

        this.selection = ko.pureComputed(() => this.items() && this.items().selection() || { items: [] });
        this.selection.subscribe((selection) => {
            if (selection.items && selection.items.length > 0) this.closeSearch();
            this.resizeHandler();
        });
        this.closeCreateGlobalTools = ko.observable(false);
        this.search = ko.observable(false);

        this.deviceInfo = ko.observable(Settings.device.getScreenInfo());
        this.isLargeDesktop = ko.observable(this.deviceInfo().device === 'lg-desktop');
        this.showBreadcrumbs = ko.observable(this._showBreadcrumbs());
        this.showContextTools = ko.observable(this._showContextTools());

        this.resizeHandler = () => {
            this.deviceInfo(Settings.device.getScreenInfo());
            this.isLargeDesktop(this.deviceInfo().device === 'lg-desktop');
            this.showBreadcrumbs(this._showBreadcrumbs());
            this.showContextTools(this._showContextTools());
        };
        window.addEventListener('resize', this.resizeHandler);
    }

    _showBreadcrumbs () {
        return Settings.device.isMobile
            ? !this.selection().items.length
            : this.isLargeDesktop()
                ? true
                : !this.selection().items.length;
    };

    _showContextTools () {
        return Settings.device.isMobile
            ? !!this.selection().items.length
            : this.isLargeDesktop()
                ? true
                : !!this.selection().items.length;
    };

    getGlobalToolsTemplate () {
        return GLOBAL_TOOLS_TEMPLATE
            .map(group => group.filter(action =>
                !!this.actions()[action] &&
                !!this.actions()[action].properties.isAllowed))
            .map(group => group.map(action => {
                // not evaluating it because it can be a ko.computed function
                this.actions()[action].properties.isAllowed =
                    funcFromBool(this.actions()[action].properties.isAllowed) ||
                    (() => true);
                return this.actions()[action];
            }))
            .filter(group => group.map(action =>
                // allow actions with .isPublic property to be visible
                // even if user is not logged in
                Settings.user.isAuthenticated
                    ? true
                    : action.properties.isPublic
            ));
    }

    // this will be used for non mobile devices
    emptyTrash () {
        return window.ReactDialogs.open({
            component: EmptyTrashDialog,
            params: {
                size: 'lg',
                onAccept: assetApi.emptyTrash
            }
        }).result.then(() => toast('EMPTY_TRASH'));
    }

    getGlobalTools ({ ignoreMobileSearch, dividers } = {}) {
        const groups = this.getGlobalToolsTemplate().filter(g => g.length > 0);
        return groups
            .reduce((acc, group) => {
                group.forEach((action, index) => {
                    if (
                        action.properties.isAllowed() &&
                        !(action.properties.isSearch && ignoreMobileSearch)
                    ) {
                        const toolbarActionProps = {
                            'data-test-id': (ignoreMobileSearch ? 'gl_toolbar__' : 'gl_dropdown__') + action.properties.title.toLowerCase().replace(/ /g, '-')
                        };

                        if (
                            dividers &&
                            index === group.length - 1 &&
                            groups.indexOf(group) < groups.length - 1
                        ) {
                            toolbarActionProps.divider = true;
                        }
                        acc.push(forToolbar(action, toolbarActionProps));
                    }
                });
                return acc;
            }, []);
    }

    // Global create tools
    getCreateTools () {
        return this.getGlobalToolsTemplate()[1];
    }

    getGlobalCreateActions () {
        let ctxActions = {};

        this.getCreateTools().forEach(action => {
            ctxActions = Object.assign(
                ctxActions,
                {
                    [action.properties.title]: {
                        text: `<span class='icon ${action.properties.icon}'></span>${action.properties.title}`,
                        action: action.action
                    }
                }
            );
        });
        return {
            count: Object.keys(ctxActions).length,
            ctxMenu: ctxActions
        };
    }

    // Make the widget behave like a toggler and not context menu
    positionGlobalCreateToolsDropdown () {
        const el = $('.context-menu');
        if (this.closeCreateGlobalTools()) {
            el.remove();
            this.closeCreateGlobalTools(false);
        } else {
            el.css({
                top: 'auto',
                left: 'auto',
                bottom: 50,
                right: 50
            });
            this.closeCreateGlobalTools(true);
        }
    }

    // Search
    viewSearch () {
        return !this.hasAction('search')() ||
            (this.hasAction('search')() && !this.search());
    }

    toggleMobileSearchWidget () {
        $('#mobile-search-widget').toggleClass('search-displayed');
        $('#mobile-search-widget #search-widget-input').focus();
    }

    toggleSearch () {
        this.items().deselectAllItems();
        this.search(!this.search());
    }

    closeSearch () {
        this.search(false);
        $('#mobile-search-widget').removeClass('search-displayed');
    }

    hasAction (action) {
        return ko.pureComputed(() => !!this.actions()[action]);
    }

    doAction (action) {
        return () => { this.actions()[action].action(this.selection().items); };
    }

    itemsDo (action) {
        return () => { this.items()[action].call(this.items()); }; // eslint-disable-line
    }

    setParams (ctx) {
        if (ctx.searchOpened) this.search(ctx.searchOpened);
    }
}

const ToolbarContextActionsVM = function (ctx) {
    this.items = ko.pureComputed(() => ctx.items);
    this.actions = ko.pureComputed(() => ctx.actions);
    this.hiddenActions = ctx.hiddenActions || [];

    this.doAction = (key, context = null) => {
        return () => this.actions()[key].action.call(context, this.items(), 'portalTools');
    };

    this.toolbarContextActions = ko.computed(function () {
        return parseActionMap({
            actionsMap: this.actions(),
            hiddenActions: this.hiddenActions,
            doAction: this.doAction,
            createToolbarActionProps: action => ({
                'data-what': 'global-tool',
                'data-which': action.name.replace(/([A-Z])/g, '-$1').toLowerCase()
            })
        });
    }, this);

    this.toolbarCtxActions = ko.computed(() => {
        return this.actions() ? this.toolbarContextActions() : [];
    });
};

!ko.components.isRegistered('toolbar-context-actions') &&
    ko.components.register('toolbar-context-actions', {
        viewModel: ToolbarContextActionsVM,
        template: { element: 'toolbar-context-actions' }
    });

export default new ActionToolbarViewModel();
