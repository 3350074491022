/* eslint-disable */
export default {
    folder: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
                height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
            <path fill="none" d="M0,0h24v24H0V0z"/>
            <path d="M5,21c-2.2092,0-4-1.7909-4-4V3h9l1.875,3H19c2.2092,0,4,1.7909,4,4v7c0,2.2091-1.7908,4-4,4H5z"/>
        </svg>
    `,
    'file-default': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M20,24.0001H4c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.1055,0.707,0.293l5,5
            C22.8945,5.4806,23,5.7349,23,6.0001v15C23,22.6568,21.6567,24.0001,20,24.0001z M4,2.0001c-0.5522,0-1,0.4478-1,1v18
            c0,0.5522,0.4478,1,1,1h16c0.5522,0,1-0.4478,1-1V6.4141l-4.415-4.415L4,2.0001z"/>
        </svg>
    `,
    'file-archive': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.895,5.4801,23,5.7345,23,6.0001v15c0,1.6567-1.3433,3-3,3H4z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16c0.5522,0,1-0.4478,1-1
            V6.4151l-4.415-4.416H15v3c0,1.1045-0.8955,2-2,2h-2c-1.1045,0-2-0.8955-2-2v-3H4c-0.5522,0-1,0.4478-1,1c0,0.0005,0,0.0015,0,0.002
            V3.0001z M11,3.0001c0,0.5522,0.4478,1,1,1s1-0.4478,1-1s-0.4478-1-1-1c-0.5518,0-0.9995,0.4473-1,0.999V3.0001z M11.4912,20.5001
            v-1.5H9v-2h2.5v-2H9v-2h2.5v-2H9v-2h3.5v2H15v2h-2.5v2H15v2h-2.5v1.5H15v2H11.4912z"/>
        </svg>
    `,
    'file-image': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.8945,5.4796,22.9995,5.7335,23,5.9981v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4141l-4.415-4.415H4c-0.5522,0-1,0.4478-1,1c0,0.0005,0,0.0015,0,0.002V3.0001z M5,20.0001v-3.4668
            l2.916-1.1548l2.917,1.1548l3.5-4.6221l4.667,2.75v5.3389H5z M5.583,11.3331c0.0054-0.9614,0.7896-1.7368,1.751-1.7319
            c0.9614-0.0044,1.7446,0.7705,1.75,1.7319c-0.0044,0.9619-0.7881,1.7383-1.75,1.7339C6.3716,13.0714,5.5879,12.2955,5.583,11.3331z"
            />
        </svg>
        `,
    'file-pts': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.1055,0.707,0.293l5,5
            C22.895,5.481,23,5.7354,23,6.0011v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4151l-4.415-4.416H4c-0.5522,0-1,0.4478-1,1C3,2.9996,3,2.9996,3,3.0001z M7.0498,17.9513
            c-2.7339-2.7334-2.7339-7.1655-0.0005-9.8994l0.0005-0.0005c2.7334-2.7339,7.1655-2.7339,9.8994-0.0005l0.0005,0.0005
            c2.7339,2.7334,2.7339,7.1655,0.0005,9.8994l-0.0005,0.0005c-1.311,1.3159-3.0928,2.0537-4.9502,2.0508
            c-1.8569,0.0024-3.6387-0.7358-4.9497-2.0513V17.9513z M7.0249,15.7852c0.2559,0.4575,0.5737,0.8779,0.9448,1.248
            c1.4399,1.436,3.5352,1.9976,5.5,1.4741l-4.5859-4.585L7.0249,15.7852z M12.9248,16.1134l1.8638,1.8638
            c0.4565-0.2559,0.876-0.5737,1.2461-0.9438c1.4399-1.4395,2.0024-3.5381,1.4761-5.5049L12.9248,16.1134z M9.814,13.0025L12,15.191
            l2.1929-2.1929L12,10.8062L9.814,13.0025z M7.9761,8.9718c-1.4395,1.4399-2.001,3.5386-1.4741,5.5049l4.585-4.585L9.2231,8.0279
            c-0.459,0.2544-0.8809,0.5713-1.2529,0.9419L7.9761,8.9718z M10.5332,7.4967l4.584,4.5869l1.8638-1.8638
            c-0.2559-0.458-0.5737-0.8779-0.9448-1.249c-1.0674-1.0698-2.5176-1.6699-4.0288-1.667c-0.5-0.0024-0.998,0.0605-1.4814,0.187
            L10.5332,7.4967z"/>
        </svg>
        `,
    'file-pdf': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.895,5.4801,23,5.7345,23,6.0001v15c0,1.6567-1.3433,3-3,3H4z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16c0.5522,0,1-0.4478,1-1
            V6.4151l-4.415-4.416H4c-0.5522,0-1,0.4478-1,1c0,0.0005,0,0.0015,0,0.002V3.0001z M4.7378,19.6832
            c-0.3354-0.3774-0.4629-0.896-0.3418-1.3862c0.2422-0.9888,1.4731-1.9468,3.7642-2.9248c0.4839-0.877,0.9224-1.7783,1.314-2.7002
            c0.3501-0.8252,0.6719-1.6631,0.9399-2.438c-0.792-1.9268-0.9429-3.894-0.355-4.8481c0.2061-0.3643,0.5767-0.6055,0.9932-0.646
            c0.4673-0.0903,0.9443,0.0977,1.2241,0.4829c0.3442,0.478,0.6621,1.5122-0.4678,4.9482c0.334,0.7144,0.7607,1.3818,1.2681,1.9858
            c0.4375,0.5122,0.9419,0.9629,1.5,1.3398c0.6841-0.0991,1.3745-0.1509,2.0659-0.1548c1.6631,0,2.6602,0.3472,2.9668,1.0298
            c0.2139,0.3862,0.2041,0.8574-0.0249,1.2349c-0.3223,0.4277-0.8481,0.6519-1.3799,0.5889
            c-1.3984-0.1104-2.7461-0.5708-3.9199-1.3389c-0.7915,0.1328-1.5737,0.314-2.3428,0.543c-1.0229,0.2998-2.0088,0.646-2.8701,0.9941
            c-0.4761,0.8525-1.0205,1.6655-1.627,2.4312c-0.7192,0.873-1.311,1.2798-1.8618,1.2798c-0.333,0.002-0.647-0.1538-0.8462-0.4204
            L4.7378,19.6832z M5.6587,18.6051c-0.0156,0.0508-0.0098,0.1055,0.0161,0.1519c0.4556-0.3545,0.8486-0.7827,1.1631-1.2671
            c-0.999,0.6201-1.1567,1.0171-1.1797,1.1162L5.6587,18.6051z M18.2065,14.9049c0.062,0,0.1138,0,0.1572-0.0059
            c-0.4678-0.1768-0.9658-0.2603-1.4658-0.2451c0.4219,0.1455,0.8623,0.229,1.3081,0.2471L18.2065,14.9049z M10.0635,14.648
            c0.4878-0.1689,1-0.332,1.5088-0.4839c0.3838-0.1138,0.7671-0.2158,1.1519-0.3071l-0.085-0.0552l0.1851-0.0278
            c-0.2646-0.2397-0.5127-0.4966-0.7432-0.769c-0.3062-0.3628-0.5864-0.7466-0.8389-1.1489l-0.0532,0.1582l-0.022-0.0508
            c-0.1582,0.4048-0.3242,0.8149-0.5,1.2231c-0.2002,0.4658-0.4141,0.9399-0.6431,1.4111l0.083-0.0342L10.0635,14.648z
            M11.1875,6.0479c-0.186,0.3706-0.249,0.791-0.1812,1.2002c0.0137,0.2393,0.0415,0.4771,0.083,0.7129
            c0.2109-0.6167,0.2627-1.2769,0.1499-1.9189l0,0L11.1875,6.0479z"/>
        </svg>
        `,
    'file-rvt': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0.0005,0.5195,0.106,0.707,0.2939l5,5
            C22.8945,5.4815,23,5.7359,23,6.0011v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4141l-4.415-4.415H4c-0.5522,0-1,0.4478-1,1C3,2.9996,3,2.9996,3,3.0001z M14.7578,17.044h-0.0459
            c-0.3711,0-0.7354-0.1001-1.0542-0.29l-0.0151-0.0078c-0.3477-0.2334-0.6245-0.5586-0.7998-0.939l-1.292-2.4912H10.5v3.5928H8.2158
            V6.9605h3.4448c0.064,0,0.1289,0,0.1929,0c0.8428-0.001,1.6655,0.2529,2.3608,0.729l0,0c0.6641,0.4746,1.0479,1.249,1.0229,2.0649
            v0.769c0.0044,0.5312-0.1719,1.0483-0.5,1.4668c-0.2764,0.3486-0.6235,0.6348-1.0181,0.8398l1.1001,2.1001
            c0.0977,0.1919,0.3003,0.3071,0.5151,0.293l0,0l0.438-0.0181v1.606l-0.186,0.0771c-0.2534,0.105-0.5254,0.1592-0.7998,0.1592
            L14.7578,17.044z M10.5039,11.46h1.1182c0.3613,0.02,0.7197-0.0771,1.021-0.2778c0.2139-0.1626,0.3335-0.4204,0.3198-0.689V9.7931
            c0.0142-0.2681-0.106-0.5254-0.3198-0.687c-0.3013-0.2012-0.6592-0.2998-1.021-0.2798h-1.1182V11.46z"/>
        </svg>
        `,
    'file-text': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0011c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.1055,0.707,0.293l5,5
            C22.895,5.482,23,5.7364,23,6.002v15c0,1.6567-1.3433,3-3,3L4,24.0011z M3,3.0011v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4151l-4.415-4.415H4c-0.5522,0-1,0.4478-1,1C3,3.0006,3,3.0006,3,3.0011z M6,19.0011v-2h12v2H6z
            M6,15.0011v-2h12v2H6z M6,11.0011v-2h7v2H6z"/>
        </svg>
    `,
    'file-vgx': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.1055,0.707,0.293l5,5
            C22.895,5.481,23,5.7354,23,6.0011v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4151l-4.415-4.416H4c-0.5522,0-1,0.4478-1,1C3,2.9996,3,2.9996,3,3.0001z M11.5942,19.1192l-4.958-2.7598
            c-0.2378-0.1323-0.3848-0.3828-0.3848-0.6548v-5.4561c-0.0005-0.271,0.145-0.5205,0.3809-0.6538l4.958-2.792
            c0.1118-0.0649,0.2388-0.0996,0.3682-0.1001c0.1279,0,0.2534,0.0327,0.3652,0.0952l5.0278,2.792
            c0.2388,0.1323,0.3867,0.3843,0.3862,0.6572v5.4561c-0.0005,0.2739-0.1499,0.5264-0.3901,0.6582l-5.0278,2.7598
            c-0.1108,0.0605-0.2349,0.0918-0.3608,0.0918c-0.1279,0.0005-0.2539-0.0317-0.3662-0.0938H11.5942z M7.7515,10.6861v4.5771
            l4.2109,2.3442l0.04-0.022v-5.0542l3.938-2.0088l-3.978-2.209L7.7515,10.6861z"/>
        </svg>
    `,
    'file-vwx': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.8945,5.4796,22.9995,5.7335,23,5.9981v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4151l-4.415-4.416H4c-0.5522,0-1,0.4478-1,1c0,0.0005,0,0.0015,0,0.002V3.0001z M9.2798,18.4513
            c-2.3364-0.9873-3.957-3.1602-4.2368-5.6812c-0.0278-0.2549-0.042-0.5112-0.042-0.7681l0,0c0-3.2251,2.2031-6.0332,5.3359-6.7998
            C10.8813,5.0689,11.4395,5.002,12,5.002c0.5322,0,1.0625,0.0601,1.5811,0.1802c3.1738,0.7354,5.4204,3.563,5.4199,6.8208l0,0
            c0.0015,0.9272-0.1831,1.8452-0.542,2.7002c-0.6865,1.6333-1.9648,2.9468-3.5791,3.6768c-0.9048,0.4097-1.8867,0.6211-2.8799,0.6201
            c-0.9341,0.002-1.8594-0.1846-2.7202-0.5479V18.4513z M6.001,12.0001c0.0034,3.3125,2.6875,5.9966,6,6
            c3.3125-0.0034,5.9966-2.6875,6-6c-0.0039-3.312-2.688-5.9961-6-6C8.688,6.003,6.0029,8.6881,6,12.0011L6.001,12.0001z
            M11.501,15.8429c-0.0776-0.0034-0.147-0.0493-0.1812-0.1191L8.728,9.4996C8.707,9.46,8.7217,9.4112,8.7607,9.3897
            C8.7749,9.3824,8.7905,9.379,8.8062,9.3805h1.3188c0.0342,0.0024,0.0649,0.0225,0.0811,0.0532l2.519,6.29
            c0.02,0.041,0.0034,0.0908-0.0381,0.1108c-0.0137,0.0073-0.0293,0.0098-0.0449,0.0083H11.501z M13.4009,13.6109l-0.6001-1.4868
            c-0.0244-0.0698-0.0244-0.146,0-0.2158l0.9932-2.4731c0.0103-0.0337,0.041-0.0571,0.0762-0.0571h1.3179
            c0.0449-0.0059,0.0859,0.0264,0.0918,0.0713c0.002,0.0161-0.001,0.0332-0.0088,0.0479l-1.7148,4.1138
            c-0.0122,0.0332-0.0439,0.0547-0.0791,0.0532c-0.0337-0.001-0.064-0.022-0.0771-0.0532H13.4009z"/>
        </svg>
    `,
    'file-vcdoc': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0011c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.895,5.481,23,5.7354,23,6.0011v15c0,1.6567-1.3433,3-3,3H4z M3,3.0011v18c0,0.5522,0.4478,1,1,1h16c0.5522,0,1-0.4478,1-1
            V6.4141l-4.415-4.415H4c-0.5522,0-1,0.4478-1,1C3,2.9996,3,3.0006,3,3.0011z M8,19.1012c-1.6567,0-3-1.3433-3-3V9.8272
            c0-1.6567,1.3433-3,3-3h8c1.6567,0,3,1.3433,3,3v6.2739c0,1.6567-1.3433,3-3,3H8z M7,9.8272v6.2739c0,0.5522,0.4478,1,1,1h8
            c0.5522,0,1-0.4478,1-1V9.8272c0-0.5522-0.4478-1-1-1H8C7.4478,8.8272,7,9.275,7,9.8272c0,0.0005,0,0.0005,0,0.001V9.8272z
            M8,15.9762V9.9991h8v4.4639h-3v1.5132H8z M9.5,14.4762h2v-1.5122h3v-1.4648h-5V14.4762z"/>
        </svg>
    `,
    'file-video': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.8945,5.4796,22.9995,5.7335,23,5.9981v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4151l-4.415-4.416H4c-0.5522,0-1,0.4478-1,1c0,0.0005,0,0.0015,0,0.002V3.0001z M9,7.0001l8,5l-8,5V7.0001
            z"/>
        </svg>
    `,
    'file-panorama': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M4,24.0001c-1.6567,0-3-1.3433-3-3v-18c0-1.6567,1.3433-3,3-3h13c0.2651,0,0.5195,0.105,0.707,0.292l5,5
            C22.8945,5.4796,22.9995,5.7335,23,5.9981v15c0,1.6567-1.3433,3-3,3L4,24.0001z M3,3.0001v18c0,0.5522,0.4478,1,1,1h16
            c0.5522,0,1-0.4478,1-1V6.4141l-4.415-4.415H4c-0.5522,0-1,0.4478-1,1c0,0.0005,0,0.0015,0,0.002V3.0001z M6.2671,19.2481
            C4.8159,18.6632,4,18.0963,4,16.6773v-5.748c0-1.4209,0.9502-2.0181,2.2671-2.5708c1.8096-0.6494,3.7241-0.9575,5.646-0.9082
            c1.9785-0.0396,3.9487,0.2676,5.8208,0.9082c1.3081,0.5381,2.2671,1.1499,2.2671,2.5708v5.748c0,1.4189-0.8428,1.9858-2.2671,2.5708
            c-1.8071,0.709-3.7461,1.019-5.6841,0.9082c-1.9702,0.106-3.9404-0.2031-5.7837-0.9072L6.2671,19.2481z M12.0503,18.856
            c1.7681,0.1069,3.5386-0.1699,5.1899-0.811c1.125-0.4609,1.3848-0.73,1.4438-1.0869L14.5,14.6329l-3.7642,4.2002
            c0.3979,0.0137,0.833,0.0239,1.313,0.0239L12.0503,18.856z M6.7725,9.5562c-1.3999,0.5879-1.4712,0.9048-1.4712,1.374v5.748
            c-0.0332,0.25,0.0459,0.502,0.2158,0.688c0.3696,0.2949,0.7871,0.5239,1.2349,0.6768c0.7588,0.3125,1.5542,0.5283,2.3672,0.6421
            l4.7588-5.3062c0.2026-0.2261,0.5352-0.2808,0.7998-0.1328l4.0239,2.2349v-4.5518c0-0.478-0.1001-0.8101-1.4609-1.3701
            c-1.7173-0.5693-3.5186-0.8428-5.3271-0.8091c-1.749-0.0464-3.4917,0.2271-5.1426,0.8062H6.7725z M7.2954,12.7213
            c0-0.9941,0.8057-1.7998,1.7998-1.7998c0.9941-0.0034,1.8027,0.7998,1.8062,1.7939c0,0.002,0,0.0039,0,0.0059
            c0,0.9941-0.8062,1.7998-1.8003,1.7998c-0.002,0-0.0039,0-0.0059,0c-0.9941,0.0005-1.8008-0.8047-1.8013-1.7988
            c0-0.001,0-0.0015,0-0.0024L7.2954,12.7213z"/>
        </svg>
        `,

    ckpt: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"> <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?> <x:xmpmeta xmlns:x="adobe:ns:meta" x:xmptk="Adobe XMP Core 5.6-c142 79.160924, 2017/07/13-01:06:39 "> <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"> <rdf:Description rdf:about=""/> </rdf:RDF> </x:xmpmeta> <?xpacket end="w"?></metadata> <defs> <style> .cls-1 { fill-rule: evenodd; } </style> </defs> <path id="comp" class="cls-1" d="M4,28V4H28V28H4Zm8-2h4V18H12v8Zm0-10h4V13H12v3ZM10,6H6v5h4V6Zm0,12H6v8h4V18ZM26,6H12v5H26V6Zm0,7H18v3h8V13Z"/> </svg>`,

    // external providers
    myDrive: `
       <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M5,22c-1.1046,0-2-0.8954-2-2V4c0-1.1046,0.8954-2,2-2h14c1.1046,0,2,0.8954,2,2v16c0,1.1046-0.8954,2-2,2H5z M5,20h14v-1H5
            V20z M19,17V4H5v13H19z M9.1,15c-0.3403,0-0.6509-0.1941-0.8-0.5l-1.1-2c-0.0999-0.2917-0.0999-0.6083,0-0.9l2.9-5.1
            c0.1686-0.2878,0.4675-0.4746,0.8-0.5h2.2c0.3602,0.022,0.6911,0.2058,0.9,0.5l3,5.1c0.0999,0.2917,0.0999,0.6083,0,0.9l-1.1,2
            c-0.1684,0.2879-0.4674,0.4747-0.8,0.5H9.1z M10.2,12.1h3.7l-1.8-3.2L10.2,12.1z"/>
        </svg>
    `,
    myFilesOneDrive: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path fill="#0C0C0C" d="M20.536,19.5H3.48c-0.1966,0.0016-0.3913-0.0375-0.572-0.115c-0.3474-0.15-0.6246-0.4268-0.775-0.774
            c-0.0775-0.1803-0.117-0.3747-0.116-0.571L2,5.96C1.9984,5.7638,2.0376,5.5693,2.115,5.389C2.2652,5.0419,2.5425,4.7653,2.89,4.616
            C3.0673,4.5399,3.2581,4.5005,3.451,4.5h7.182c0.2593-0.0016,0.5164,0.0488,0.756,0.148c0.227,0.0958,0.4381,0.2256,0.626,0.385
            c0.1854,0.158,0.3531,0.3357,0.5,0.53c0.1435,0.1892,0.277,0.3858,0.4,0.589v0.005c0.084,0.142,0.161,0.271,0.238,0.382
            c0.0644,0.0932,0.1419,0.1768,0.23,0.248c0.082,0.065,0.1747,0.1151,0.274,0.148c0.1297,0.0384,0.2648,0.0559,0.4,0.052h6.463
            c0.1966-0.0009,0.3913,0.0386,0.572,0.116c0.3475,0.1493,0.6248,0.4259,0.775,0.773c0.0774,0.1804,0.1169,0.3747,0.116,0.571
            l0.016,9.59c0.0018,0.1966-0.0374,0.3914-0.115,0.572c-0.1507,0.347-0.4278,0.6237-0.775,0.774
            c-0.177,0.0757-0.3675,0.1149-0.56,0.115L20.536,19.5z M4.016,17.5H20L19.985,9H13c-0.13,0-0.228,0.06-0.52,0.282
            C11.9718,9.7284,11.3232,9.9825,10.647,10H4.006L4.016,17.5z M10.023,8h0.624c0.2,0,0.314-0.077,0.625-0.312l0.159-0.119
            c-0.09-0.138-0.172-0.275-0.247-0.4l-0.037-0.064c-0.071-0.113-0.148-0.225-0.229-0.332c-0.0588-0.0768-0.1259-0.1469-0.2-0.209
            c-0.0273-0.0236-0.0575-0.0434-0.09-0.059H4v1.5L10.023,8z"/>
        </svg>
    `,

    // google drive:
    sharedWithMe: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M15,8c-0.0183-1.3727-0.4737-2.7038-1.3-3.8C14.1228,4.0771,14.5598,4.0099,15,4c2.2091,0,4,1.7909,4,4s-1.7909,4-4,4
            c-0.4066-0.0178-0.8096-0.0849-1.2-0.2h-0.1C14.5263,10.7038,14.9817,9.3727,15,8z M16.7,13.1c1.3563,0.8476,2.2146,2.3029,2.3,3.9
            v3h4v-3C23,14.8,19.4,13.5,16.7,13.1z M9,6C7.8954,6,7,6.8954,7,8s0.8954,2,2,2s2-0.8954,2-2C10.9967,6.8968,10.1032,6.0033,9,6
            M9,15c-2.7,0-5.8,1.3-6,2v1h12v-1C14.8,16.3,11.7,15,9,15 M9,4c2.2091,0,4,1.7909,4,4s-1.7909,4-4,4s-4-1.7909-4-4
            C5.0066,5.7936,6.7936,4.0066,9,4z M9,13c2.7,0,8,1.3,8,4v3H1v-3C1,14.3,6.3,13,9,13z"/>
        </svg>
    `,
    sharedWithMeOneDrive: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M12,22.5c-0.0028-2.4841-2.0159-4.4972-4.5-4.5c-2.4841,0.0028-4.4972,2.0159-4.5,4.5c0,0.5523-0.4477,1-1,1s-1-0.4477-1-1
            c0.002-2.3908,1.3152-4.588,3.42-5.722C3.5135,15.9289,2.9993,14.7421,3,13.5C3.0028,11.0159,5.0159,9.0028,7.5,9
            c1.2421-0.0007,2.4289,0.5135,3.278,1.42c0.6054-1.1185,1.5235-2.0366,2.642-2.642C12.5135,6.9289,11.9993,5.7421,12,4.5
            c0.0028-2.4841,2.0159-4.4972,4.5-4.5c2.4841,0.0028,4.4972,2.0159,4.5,4.5c0.0004,1.2417-0.5137,2.4281-1.42,3.277
            c2.1051,1.1342,3.4183,3.3318,3.42,5.723c0,0.5523-0.4477,1-1,1s-1-0.4477-1-1c-0.0028-2.4841-2.0159-4.4972-4.5-4.5
            c-2.4841,0.0028-4.4972,2.0159-4.5,4.5c0.0004,1.2417-0.5137,2.4281-1.42,3.277c2.1051,1.1342,3.4183,3.3318,3.42,5.723
            c0,0.5523-0.4477,1-1,1S12,23.0523,12,22.5z M5,13.5C5,14.8807,6.1193,16,7.5,16s2.5-1.1193,2.5-2.5S8.8807,11,7.5,11
            S5,12.1193,5,13.5z M14,4.5C14,5.8807,15.1193,7,16.5,7S19,5.8807,19,4.5S17.8807,2,16.5,2S14,3.1193,14,4.5z"/>
        </svg>
    `,
    sharedDrive: `
       <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M19,22H5c-1.1046,0-2-0.8954-2-2v-2h18v2C21,21.1046,20.1046,22,19,22z M21,17H3V4c0-1.1046,0.8954-2,2-2h14
            c1.1046,0,2,0.8954,2,2V17z M9,6.2c-2.0987,0-3.8,1.7013-3.8,3.8l0,0c0,2.0987,1.7013,3.8,3.8,3.8l0,0
            c1.6951-0.0118,3.1713-1.1599,3.6-2.8H16v2h2v-2h1V9h-6.4C12.1065,7.4037,10.6687,6.2854,9,6.2z M9,11.7
            c-0.9289-0.0237-1.6763-0.7711-1.7-1.7C7.3237,9.0711,8.0711,8.3237,9,8.3c0.9289,0.0237,1.6763,0.7711,1.7,1.7
            C10.6763,10.9289,9.9289,11.6763,9,11.7z"/>
        </svg>
    `
};
/* eslint-enable */
