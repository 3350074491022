import { queryString, FilePath, StreamParser } from '../lib';
import { Dialog, pubsub, IntegrationExpiredRevokedError } from '../base';
import { ensureObservable } from '../lib/knockout-utils';
import { Asset, AssetId } from '../asset/asset';
import { UploadFolder, get as getUploader } from '../upload/upload';
import { progressTracker } from '../progress/progress';
import assetApi from '../asset/api';
import { fileResourceId } from '../preview/file';
import { Breadcrumb } from './breadcrumb.vm';
import { itemSorterMixins } from '../view/sorter';
import { AssetCollection, createAssetViewModel, commonAssetViewMixin } from './asset.vm';
import { assetColumns } from './asset-columns';
import { SharingInfo, OwnerInfo } from '../share/api';
import { homePushNotifierHandlersMixin } from '../view/push-notifier-mixin';
import { action as createFolderAction } from './create-folder.vm';
import { actions as assetActions } from '../asset/actions';
import { parseSharedDriveLookup } from './google-shared-drives';
import { StorageProvider } from './storage-providers';
import { handleSharedDriveRoot } from './onedrive-shared-drives';
import { loadOneDrivePages } from '../Sidebar/Integrations/api';

const hasLoadedOneDrivePages = () => loadOneDrivePages(true).length > 0;

const folderViewMixin = {
    setupAssets () {
        this.files = ko.pureComputed(() => this.assets.items().filter(asset => asset.isFile));
        this.folders = ko.pureComputed(() => this.assets.items().filter(asset => asset.isFolder));
    },
    sortAssets (comparator) {
        const files = this.items().filter(asset => asset.isFile);
        files.sort(comparator);
        const folders = this.items().filter(asset => asset.isFolder);
        folders.sort(comparator);
        this.items(folders.concat(files));
    },
    addOrUpdateAsset (assetId, data, isFolder = false) {
        const asset = this.assets.find(assetId);
        const assetData = {
            ...data,
            owner_info: ko.isObservable(this.ownerInfo) ? this.ownerInfo() : this.ownerInfo
        };

        if (!asset) {
            this.assets.add(this.itemViewModelFactory(assetData, isFolder));
            this.sort();
        } else if (!isFolder && asset.lastModified < assetData.last_modified) {
            this.assets.update([this.itemViewModelFactory(assetData, isFolder)]);
        }
    },
    renameAsset (oldAssetId, newAssetId, data) {
        const oldAsset = this.assets.find(oldAssetId);
        const newAsset = new Asset(
            {
                ...data,
                thumbnail: oldAsset.thumbnail
            },
            oldAsset.isFolder,
            oldAsset.isLatestVersion,
            oldAsset.ownerInfo,
            oldAsset.sharingInfo,
            oldAsset.parent
        );
        this.assets.remove(oldAssetId);
        this.assets.add(createAssetViewModel(
            newAsset,
            this.assets,
            { routerCtx: this.routerCtx }
        ));
        this.sort();
    },
    removeAsset (assetId) {
        this.assets.remove(assetId);
    }
};

const breadcrumbNames = {
    get (storageType) {
        return this.byStorageType[storageType];
    },
    byStorageType: {
        [Settings.DEFAULT_STORAGE]: gettext('Home'),
        dropbox: 'Dropbox',
        google_drive: 'Google Drive',
        one_drive: 'OneDrive'
    }
};

// TODO: Dont forget to do the same for select dialog
// When you update these, update the ones in select-file.vm also
function createBreadcrumbs (storageType, currentFolder, ownerInfo) {
    function createHomeBreadcrumb () {
        return new Breadcrumb(
            breadcrumbNames.get(storageType),
            `${Settings.HOME_URL}${storageType === Settings.DEFAULT_STORAGE ? '' : storageType + '/'}`,
            false,
            0
        );
    }

    function createOwnFolderBreadcrumbs (folder) {
        const parts = (storageType !== StorageProvider.ONE_DRIVE.storageType)
            ? FilePath(folder, storageType).breadcrumbs()
            : (hasLoadedOneDrivePages() ? handleSharedDriveRoot(FilePath(folder, storageType).breadcrumbs()) : []);
        const home = createHomeBreadcrumb();
        const children = parts.map(([name, path], i) =>
            new Breadcrumb(name, `${Settings.HOME_URL}${storageType}/${Settings.user.login}/${path}/`, false, i + 1));
        return [home, ...children];
    }

    function createMountedFolderBreadcrumbs () {
        const mountPoint = ownerInfo.mountPoint;
        const mountedPart = FilePath(currentFolder).removePrefix(mountPoint.path);
        let ownCrumbs = createOwnFolderBreadcrumbs(mountPoint.mount_path);
        ownCrumbs = ownCrumbs.slice(0, ownCrumbs.length - 1);
        let level = ownCrumbs.length + 1;
        const mountedCrumb = new Breadcrumb(
            FilePath(mountPoint.mount_path).name(),
            `${Settings.HOME_URL}${storageType}/${ownerInfo.owner}/${mountPoint.path}/`,
            false,
            ++level
        );
        const sharedParts = mountedPart
            ? FilePath(mountedPart).breadcrumbs().map(([name, path], i) => [name, `${mountPoint.path}/${path}`])
            : [];
        const sharedCrumbs = sharedParts.map(([name, path], i) =>
            new Breadcrumb(
                name,
                `${Settings.HOME_URL}${storageType}/${ownerInfo.owner}/${path}/`,
                i === sharedParts.length - 1,
                ++level
            )
        );
        return [...ownCrumbs, mountedCrumb, ...sharedCrumbs];
    }

    return ownerInfo.isShared
        ? (
            ownerInfo.mountPoint
                ? createMountedFolderBreadcrumbs()
                : [createHomeBreadcrumb()]
        )
        : createOwnFolderBreadcrumbs(currentFolder);
}

const service = (() => {
    const buildUrl = options => {
        const { storageType, owner, folder, srcId } = options;
        const [driveId, path] = parseSharedDriveLookup(folder);

        const url = driveId
            ? `${Settings.API_ROOT}${storageType}/folder/:stream/o:${Settings.user.login}/p:driveId_${driveId}/${path !== '' ? path + '/' : ''}`
            : `${Settings.API_ROOT}${storageType}/folder/:stream/o:${owner}/${path !== '' ? 'p:' + path + '/' : ''}`;
        const params = {
            sections: '(sharing_info,owner_info)',
            fields: '(asset.sharing_info.link_visits_count)'
        };

        return url +
            (storageType === StorageProvider.ONE_DRIVE.storageType ? `id:${srcId}` : '') +
            queryString.stringify(params);
    };

    return {
        load: options => $.ajax({
            async: true,
            url: buildUrl(options),
            dataType: 'text',
            cache: false,
            xhr: () => {
                const xhr = $.ajaxSettings.xhr();
                const parser = new StreamParser();

                xhr.onprogress = e => {
                    const response = e.currentTarget.response;

                    parser.loadMore(response);

                    let text = parser.next();
                    do {
                        text && options.chunkCallback(text);
                        text = parser.next();
                    } while (text);
                };
                return xhr;
            }
        })
    };
})();

let uploader = null;
class HomeViewModel {
    constructor (ctx) {
        this.ctx = ctx;
        this.title = 'Home';
        this.storageType = ko.observable(ctx.params.provider || Settings.DEFAULT_STORAGE);
        this.owner = ko.observable(ctx.params.owner || Settings.user.login);
        this.currentFolder = ko.observable(
            ctx.params.folder ? decodeURIComponent(ctx.params.folder).replace(/\/$/, '') : ''
        );
        this.ownerInfo = ko.observable(OwnerInfo.fromLogin(this.owner()));
        this.sharingInfo = ko.observable(null);
        this.breadcrumbs = ko.observable(this.getProviderBreadcrumbs());
        this.responseItems = [];

        this.streamLoadingHandlers = {
            stats: data => {
                this.responseItems = new Array(data.folders + data.files);
            },
            folders: data => {
                const assets = data.map(([id, item]) => {
                    const asset = this.itemViewModelFactory(item, true);
                    this.responseItems[id] = asset;
                    return asset;
                });
                this.assets.concat(assets);
                this.sort();
            },
            files: data => {
                const assets = data.map(([id, item]) => {
                    const asset = this.itemViewModelFactory(item, false);
                    this.responseItems[id] = asset;
                    return asset;
                });
                this.assets.concat(assets);
                this.sort();
            },
            thumbnails: data => {
                data.forEach(([id, thumbnail]) => {
                    const oldAsset = this.responseItems[id];
                    const newData = { ...oldAsset.data, thumbnail };
                    const newAsset = Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        { data: newData, thumbnail }
                    );
                    this.responseItems[id] = newAsset;
                    // it's a bad idea to replace all assets, because thumbnail info
                    // is not returned for all assets
                    // meaning some assets will be lost. better replace only the assets with thumbnails
                    this.assets.replace(oldAsset, newAsset);
                });
            },
            thumbnails_3d: data =>
                data.forEach(([id, thumbnail]) => {
                    const oldAsset = this.responseItems[id];
                    const newData = { ...oldAsset.data, thumbnail_3d: thumbnail };
                    const newAsset = Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        { data: newData, thumbnail }
                    );
                    this.responseItems[id] = newAsset;
                    this.assets.replace(oldAsset, newAsset);
                }),
            root_owner_info: data => {
                this.ownerInfo(OwnerInfo.create(
                    data, data.owner !== Settings.user.login
                ));
                this.breadcrumbs(createBreadcrumbs(
                    this.storageType(),
                    this.currentFolder(),
                    this.ownerInfo()
                ));
            },
            root_sharing_info: data => {
                this.sharingInfo(SharingInfo.create(data));
            },
            sharing_info: data =>
                data.forEach(([id, sharing_info]) => {
                    const oldAsset = this.responseItems[id];
                    const sharingInfo = SharingInfo.create(sharing_info);
                    const newData = { ...oldAsset.data, sharing_info };
                    const newAsset = Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        {
                            data: newData,
                            sharingInfo,
                            isShared: sharingInfo.isShared,
                            sharedWith: sharingInfo.sharedWith.map(sw => sw.username).filter(sw => !!sw)
                        }
                    );
                    this.responseItems[id] = newAsset;
                    this.assets.replace(oldAsset, newAsset);
                }),
            owner_info: data =>
                data.forEach(([id, owner_info]) => {
                    const oldAsset = this.responseItems[id];
                    const ownerInfo = OwnerInfo.create(
                        owner_info, owner_info.owner !== Settings.user.login
                    );
                    const newData = { ...oldAsset.data, owner_info };
                    const newAsset = Asset.reassignActions(Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        {
                            data: newData,
                            ownerInfo,
                            isOwn: !ownerInfo.isShared,
                            owner: ownerInfo.owner
                        }
                    ), assetActions);
                    newAsset.viewUrl = this.getAssetViewUrl(newAsset);
                    this.responseItems[id] = newAsset;
                    this.assets.replace(oldAsset, newAsset);
                }),
            related: data =>
                data.forEach(([id, related]) => {
                    const oldAsset = this.responseItems[id];
                    const newData = { ...oldAsset.data, related };
                    const newAsset = Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        {
                            data: newData
                        }
                    );
                    this.responseItems[id] = newAsset;
                    this.assets.replace(oldAsset, newAsset);
                }),
            error: data => {
                if (data.detail === 'Credentials expired or revoked.') {
                    IntegrationExpiredRevokedError.showErrorDialog();
                } else {
                    if (data.status === 404) {
                        Dialog.alert({ name: 'dialog-404-error' });
                    } else {
                        Dialog.alert({ name: 'dialog-internal-server-error' });
                    }
                }
            }
        };

        this.dropUpload = {
            drop: ko.observable(false),
            folder: ko.observable(this.currentFolder() !== '' ? this.currentFolder() : gettext('Home')),
            inCurrentFolder: ko.observable(true)
        };

        this.init({
            ctx,
            name: 'home',
            toolbarActions: {
                upload: {
                    action: () => uploader.browse(this.getUploadFolder()),
                    properties: {
                        isAllowed: this.storageType() === 's3',
                        title: gettext('Upload files'),
                        icon: 'icon-upload'
                    }
                },
                createFolder: createFolderAction(this),
                toggleListThumbs: true,
                search: true
            },
            assetKeyActions: {
                delete: {
                    predicate: event => event.which === 46,
                    handler: () => {
                        const selection = this.assets.selection();
                        if (selection.hasAction('delete')) {
                            selection.doAction('delete')();
                        }
                    }
                }
            },
            assetActionListeners: {
                share: function (modified) {
                    const unsharedWithMe = modified.filter(a =>
                        !a.sharingInfo.sharedWith.some(sw => sw.login === Settings.user.login) &&
                        a.ownerInfo.owner !== Settings.user.login
                    );
                    unsharedWithMe.forEach(this.assets.remove.bind(this.assets));

                    const updated = modified.filter(a =>
                        !unsharedWithMe.find(u => u.resourceUri === a.resourceUri)
                    );
                    this.handleUpdateAction(updated);
                },
                manageLinks: function (updated) {
                    this.handleUpdateAction(updated);
                },
                rename: function (updated) {
                    this.handleUpdateAction(updated);
                },
                delete: function (deleted) {
                    deleted
                        .forEach(this.assets.remove.bind(this.assets));
                },
                restore: function (restored) {
                    this.handleUpdateAction(restored);
                }
            },
            service,
            assets: Object.assign(
                new AssetCollection(ko.observableArray([]), this),
                { sort: this.sortAssets }
            ),
            pushNotificationHandlers: this.homePushNotificationHandlers,
            columns: assetColumns.home
        });

        this.setupAssets();
        this.uploadEnabled = this.storageType() === Settings.DEFAULT_STORAGE;
        !uploader && getUploader({
            bucket: 's3',
            progressTracker,
            browseButtonSelector: 'home-upload',
            progressNotification: true
        }).then(uploaderInstance => {
            uploader = uploaderInstance;
        });
        pubsub.subscribe(this, 'upload.finished', this.addUploadedFile);
    }

    partialUpdateAssets = data =>
        this.streamLoadingHandlers[data.type] && this.streamLoadingHandlers[data.type](data.data);

    load () {
        ensureObservable(this, 'loading', true);
        this.service.load({
            storageType: this.storageType(),
            owner: this.owner(),
            folder: this.currentFolder(),
            chunkCallback: this.partialUpdateAssets,
            srcId: queryString.parse(window.location.search).src_id
        })
            .then(data => {
                this.loading(false);
            }, error => {
                Dialog.alert({ name: 'dialog-internal-server-error' });
                this.loading(false);
            });
    }

    getProviderBreadcrumbs () {
        if (this.storageType() === StorageProvider.ONE_DRIVE.storageType && !hasLoadedOneDrivePages()) {
            loadOneDrivePages().then((data) => {
                this.breadcrumbs(createBreadcrumbs(
                    this.storageType(),
                    this.currentFolder(),
                    this.ownerInfo()
                ));
            });
            return [];
        } else {
            return createBreadcrumbs(
                this.storageType(),
                this.currentFolder(),
                this.ownerInfo()
            );
        }
    }

    getAssetViewUrl (asset) {
        const rootMounted = this.ownerInfo
            ? this.ownerInfo().isShared
            : asset.ownerInfo.isShared;
        const mountPoint = asset.ownerInfo.mountPoint;
        const result = asset.isFolder
            ? `${Settings.FILES_PREFIX}${asset.storageType}/${asset.owner}/${mountPoint && !rootMounted ? mountPoint.path : asset.prefix}/`
            : fileResourceId.fromAsset(asset).asUrl();
        return `${result}?src_id=${asset.srcId}`;
    }

    itemViewModelFactory (asset, isFolder) {
        const queryParams = queryString.parse(window.location.search);
        asset = asset instanceof Asset
            ? asset
            : isFolder
                ? Asset.fromFolder(asset, { assetActions })
                : Asset.fromFile(asset, { assetActions });
        return createAssetViewModel(
            asset,
            this.assets,
            {
                routerCtx: this.routerCtx,
                select: queryParams && queryParams.select === asset.name
            }
        );
    }

    startDragDropUpload (files) {
        uploader.start(files, this.getUploadFolder());
    }

    startDragDropFolderUpload (file, parentPath) {
        const uploadFolderPath = this.currentFolder() === ''
            ? `${parentPath.slice(1)}`
            : `${this.currentFolder()}/${parentPath.slice(1)}`;
        const folder = UploadFolder.fromAssets(
            uploadFolderPath,
            this.ownerInfo(),
            []
        );
        uploader.start([file], folder);
    }

    getUploadFolder () {
        return UploadFolder.fromAssets(
            this.currentFolder(), this.ownerInfo(), this.assets.items()
        );
    }

    addUploadedFile (data) {
        const assetId = AssetId.fromData(data);
        this.shouldHandleAsset(assetId) && this.addOrUpdateAsset(assetId, data, false);
    }

    addJobResult ({ storageType, owner, prefix }) {
        const folder = FilePath(prefix).folder();
        if (this.currentFolder() === folder && this.storageType() === storageType) {
            const oldAsset = this.assets.items().find(a => a.prefix === prefix);

            // TODO: maybe job resource should return id
            assetApi.getBaseFileInfo(storageType, owner, prefix)
                .then(data => {
                    const asset = this.itemViewModelFactory(data, false);
                    if (oldAsset && oldAsset.versionId !== asset.versionId) {
                        this.assets.add(asset);
                        this.sort();
                    }
                });
        }
    }

    setThumbnailUrl (assetId, thumbnailUrl) {
        const asset = this.assets.find(assetId);
        if (asset) {
            const newAsset = Asset.update(asset, (data, options) => {
                data.thumbnail_3d = thumbnailUrl;
            });
            this.assets.update([
                createAssetViewModel(
                    newAsset, this.assets, { routerCtx: this.routerCtx }
                )
            ]);
        }
    }

    shouldHandleAsset (assetId) {
        return this.storageType() === assetId.storageType &&
            this.currentFolder() === assetId.folder;
    }
}

Object.assign(
    HomeViewModel.prototype,
    commonAssetViewMixin,
    folderViewMixin,
    itemSorterMixins.client,
    {
        columns: assetColumns.home
    },
    homePushNotifierHandlersMixin
);

export {
    HomeViewModel,
    folderViewMixin
};
