import axios from 'axios';
import * as queryString from './query-string';

const DEFAULT_PAGE_SIZE = 25;

class Paginator {
    /*
     * Server resource schema:
     * {
     *   count: Number,
     *   next: Url,
     *   previous: Url,
     *   results: Array
     * }
     */

    static fromUrl (url, options = { qs: {} }) {
        const baseOptions = {
            url: queryString.buildUrl(
                url,
                Object.assign({}, options.qs, { limit: options.pageSize || DEFAULT_PAGE_SIZE })
            ),
            method: 'GET',
            dataType: 'json'
        };
        return Paginator.request(Object.assign(baseOptions, options))
            .then(data => new Paginator(data, options.pageSize, url))
            .catch(console.error);
    }

    static all (url) {
        return new Promise(function (resolve, reject) {
            let result = [];

            getNext(Paginator.fromUrl(url));

            function getNext (promise) {
                return promise.then(function (page) {
                    if (!page.isEmpty) {
                        result = result.concat(page.current);
                        getNext(page.next());
                    }
                    if (page.isEmpty) {
                        resolve(result);
                    }
                });
            }
        });
    }

    constructor (data, pageSize = DEFAULT_PAGE_SIZE, url = null) {
        this.data = data;
        this.pageSize = pageSize;
        this.url = url;
        this.current = data.results.filter(item => !!item);
        this.nullItems = data.results.length - this.current.length;
        this.isEmpty = this.current.length === 0;
        this.hasNext = !!data.next;
        this.hasPrevious = !!data.previous;
        this.isOnePage = !this.hasNext && !this.hasPrevious;
    }

    next (offsetCorrection = 0) {
        if (this.data.next) {
            return Paginator.fromUrl(
                applyUrlToCurrentLocation(
                    applyOffsetCorrection(
                        queryString.parseUrl(this.data.next), offsetCorrection)));
        } else {
            return Promise.resolve(new Paginator({
                count: this.count,
                previous: this.url,
                next: null,
                results: []
            }, this.pageSize));
        }
    }

    previous (offsetCorrection = 0) {
        if (this.data.previous) {
            return Paginator.fromUrl(
                applyUrlToCurrentLocation(
                    applyOffsetCorrection(
                        queryString.parseUrl(this.data.previous), offsetCorrection)));
        } else {
            return Promise.resolve(new Paginator({
                count: this.count,
                previous: null,
                next: this.url,
                results: []
            }, this.pageSize));
        }
    }
}

Paginator.request = axios;

function applyOffsetCorrection (parsedUrl, correction) {
    if (correction) {
        const qs = queryString.parse(parsedUrl.search);
        qs.offset = parseInt(qs.offset) + correction;
        parsedUrl.search = queryString.stringify(qs);
    }
    return parsedUrl;
}

function applyUrlToCurrentLocation (parsedUrl) {
    const { protocol, host, pathname, search } = parsedUrl;
    return `${protocol}//${host}${pathname}${search}`;
}

export { Paginator };
