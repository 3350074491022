import { request } from '../base/axios';
import storage from '../preview/storage';

function getExtraJobOptions () {
    if (getExtraJobOptions.value === undefined) {
        getExtraJobOptions.value = storage.get('extraJobOptions', {});
    }
    return getExtraJobOptions.value;
}

const baseUrl = (storageType, owner, prefix, isFolder, srcId, versionId = null, action = null) => {
    const idPart = srcId ? `id:${srcId}/` : '';
    const versionIdPart = versionId ? `v:${versionId}/` : '';
    const actionPart = action ? `:${action}/` : '';
    const assetType = isFolder ? 'folder' : 'file';
    return `${Settings.API_ROOT}${storageType}/${assetType}/${actionPart}o:${owner}/p:${prefix.replace(/\/*$/, '')}/${idPart}${versionIdPart}`;
};

const methods = {
    delete (asset, forever = false) {
        const { storageType, owner, prefix, isFolder, srcId } = asset;
        return request({
            url: `${baseUrl(storageType, owner, prefix, isFolder, srcId)}${forever ? '?permanent=true&versioning=on' : ''}`,
            method: 'DELETE'
        });
    },
    deleteForever (asset) {
        return methods.delete(asset, true);
    },
    emptyTrash () {
        return request({
            url: `${window.Settings.API_ROOT}empty-trash/`,
            method: 'POST'
        });
    },
    share (asset, data) {
        return request({
            url: `${Settings.API_ROOT}${asset.storageType}/share/?fields=(resources)`,
            method: 'POST',
            data,
            contentType: 'application/json; charset=utf-8'
        });
    },
    shareMultiple (storageType, data) {
        return request({
            url: `${Settings.API_ROOT}${storageType}/share/?fields=(resources)`,
            method: 'POST',
            data,
            contentType: 'application/json; charset=utf-8'
        });
    },
    unshareWith (asset, data) {
        const { storageType, owner, isFolder, srcId } = asset;
        const prefix = asset.ownerInfo.mountPoint
            ? asset.ownerInfo.mountPoint.path
            : asset.prefix;

        return request({
            url: baseUrl(storageType, owner, prefix, isFolder, srcId, null, 'unshare'),
            method: 'DELETE',
            data,
            contentType: 'application/json; charset=utf-8'
        });
    },
    unshare (asset) {
        const { storageType, owner, prefix, isFolder, srcId } = asset;
        return request({
            url: baseUrl(storageType, owner, prefix, isFolder, srcId, null, 'unshare'),
            method: 'DELETE'
        });
    },
    createLink (asset) {
        const { storageType, owner, prefix, isFolder, srcId } = asset;
        return request({
            url: baseUrl(storageType, owner, prefix, isFolder, srcId, null, 'create_link'),
            method: 'PUT'
        });
    },
    removeLink (asset) {
        const linkUUID = asset.sharingInfo.linkUuid;
        return request({
            url: `${Settings.API_ROOT}shareable_link/:remove/${linkUUID}/`,
            method: 'DELETE'
        });
    },
    updateLink (asset, data) {
        const linkUUID = asset.sharingInfo.linkUuid;
        return request({
            url: `${Settings.API_ROOT}shareable_link/:update/${linkUUID}/`,
            method: 'PUT',
            data,
            contentType: 'application/json; charset=utf-8'
        });
    },
    linkVisit (uuid) {
        const url = `${Settings.API_ROOT}shareable_link/:visit/${uuid}/${window.location.search}`;
        return request({
            url,
            method: 'GET',
            contentType: 'application/json; charset=utf-8'
        });
    },
    emailLink (data) {
        return request({
            url: `${Settings.API_ROOT}shareable_link/:email/`,
            method: 'POST',
            data,
            contentType: 'application/json; charset=utf-8'
        });
    },
    exportPdf (asset) {
        return request({
            url: Settings.JOBS_API_URL,
            method: 'POST',
            data: {
                job_type: 'export_pdf',
                file_version: asset.forAssetField(),
                options: {
                    output_storage_type: asset.storageType,
                    options: JSON.stringify(getExtraJobOptions())
                }
            },
            contentType: 'application/json; charset=utf-8'
        });
    },
    generate3DModel (asset, options) {
        return request({
            url: Settings.JOBS_API_URL,
            method: 'POST',
            data: {
                job_type: 'distill',
                file_version: asset.forAssetField(),
                options: {
                    ...options,
                    options: JSON.stringify({
                        ...JSON.parse(options.options || '{}'),
                        ...getExtraJobOptions()
                    })
                }
            },
            contentType: 'application/json; charset=utf-8'
        });
    },
    revitExport (asset, options) {
        const data = {
            url: Settings.JOBS_API_URL,
            method: 'POST',
            data: {
                job_type: 'generic',
                file_version: asset.forAssetField(),
                options: {
                    ...options,
                    operation: 'revit_export',
                    options: JSON.stringify({
                        ...JSON.parse(options.options || '{}'),
                        ...getExtraJobOptions()
                    })
                }
            },
            contentType: 'application/json; charset=utf-8'
        };
        return request(data);
    },
    runPhotogrammetryJob (assets, outputLocation, options) {
        const first = assets[0];
        return request({
            url: Settings.JOBS_API_URL,
            method: 'POST',
            data: {
                job_type: 'photogram',
                file_version: first.forAssetField(),
                options: {
                    src_storage_type: first.storageType,
                    src_file_versions: assets.map(asset => asset.forAssetField()),
                    output_storage_type: outputLocation.storageType,
                    output_location_owner: outputLocation.owner,
                    output_location: outputLocation.folderPrefix,
                    options: JSON.stringify({
                        ...options,
                        ...getExtraJobOptions()
                    })
                }
            },
            contentType: 'application/json; charset=utf-8'
        });
    },
    upsampling (asset, params) {
        const folderPrefix = params.saveAsForm.saveTo.folderPrefix;

        const data = {
            url: Settings.JOBS_API_URL,
            method: 'POST',
            data: {
                file_version: asset.forAssetField(),
                job_type: 'generic',
                options: {
                    operation: 'upsample',
                    output_storage_type: params.saveAsForm.saveTo.storageType,
                    output_location_owner: params.saveAsForm.saveTo.owner,
                    output_location: `${folderPrefix}${folderPrefix ? '/' : ''}` +
                        `${asset.baseName}${params.saveAsForm.suffix}${asset.extension}`,
                    options: JSON.stringify({
                        scale_factor: +params.scaleFactor,
                        ...getExtraJobOptions()
                    })
                }
            },
            contentType: 'application/json; charset=utf-8'
        };
        return request(data);
    },
    styleTransfer (asset, params) {
        const folderPrefix = params.saveAsForm.saveTo.folderPrefix;

        const data = {
            url: Settings.JOBS_API_URL,
            method: 'POST',
            data: {
                file_version: asset.forAssetField(),
                job_type: 'generic',
                options: {
                    operation: 'style_transfer',
                    output_storage_type: params.saveAsForm.saveTo.storageType,
                    output_location_owner: params.saveAsForm.saveTo.owner,
                    output_location: `${folderPrefix}${folderPrefix ? '/' : ''}` +
                        `${asset.baseName}${params.saveAsForm.suffix}${asset.extension}`,
                    options: JSON.stringify({
                        style_name: params.styleName,
                        ...getExtraJobOptions()
                    })
                }
            },
            contentType: 'application/json; charset=utf-8'
        };
        return request(data);
    },
    get (storageType, owner, path, isShared, srcId) {
        const method = isShared ? this.getSharedFile : this.getFile;
        return method.call(this, storageType, owner, path, srcId);
    },
    getFile (storageType, owner, path, srcId = '') {
        return request({
            url: `${baseUrl(storageType, owner, path, false, srcId)}?versioning=full&related=(vwx,vgx,pdf,panorama,vgx_views)&fields=(sharing_info,sharing_info.link_visits_count,sharing_info.allow_comments,thumbnail_3d,flags,file_type,label,src_id,_.thumbnail_3d,_.flags,_.file_type,_.label,_.src_id)`,
            method: 'GET'
        });
    },
    getBaseFileInfo (storageType, owner, path) {
        return request({
            url: `${baseUrl(storageType, owner, path, false, null)}/?fields=(thumbnail_3d,file_type,src_id,_.thumbnail_3d,_.file_type,_.src_id)`,
            method: 'GET'
        });
    },
    getSharedFile (storageType, owner, path, srcId = '') {
        const idQuery = srcId ? `id:${srcId}/` : '';
        return request({
            url: `${Settings.API_ROOT}${storageType}/shared_file/o:${owner}/p:${path.replace(/\/*$/, '')}/${idQuery}?versioning=on&related=(vwx,vgx,pdf,panorama,vgx_views)&fields=(file.thumbnail_3d,file.flags,file.file_type,file.label,file.src_id,file._.thumbnail_3d,file._.flags,file._.file_type,file._.label,file._.src_id)`,
            method: 'GET'
        });
    },
    getSharedInfo (asset) {
        const { storageType, owner, srcId, isFolder } = asset;
        const idQuery = srcId ? `id:${srcId}/` : '';
        const isFolderQuery = isFolder ? '?is_folder' : '';
        return request({
            url: `${Settings.API_ROOT}${storageType}/shared_info/o:${owner}/p:${asset.mountRealPath().replace(/\/*$/, '')}/${idQuery}${isFolderQuery}`,
            method: 'GET'
        });
    },
    getLatest (asset) {
        const { storageType, owner, prefix, srcId } = asset;
        return request({
            url: `${baseUrl(storageType, owner, prefix, false, srcId)}?related=(vwx,vgx,pdf,panorama)&fields=(thumbnail_3d,sharing_info,flags,file_type,label,src_id,_.thumbnail_3d,_.flags,_.file_type,_.label,_.src_id)`,
            method: 'GET'
        });
    },
    getFolder (storageType, owner, path) {
        return request({
            url: `${Settings.API_ROOT}${storageType}/folder/o:${owner}/${path !== '' ? 'p:' + path : ''}`
        });
    },
    getFromLink (uuid, path = null, src_id = null) {
        return request({
            url: `${Settings.API_ROOT}shareable_link/:metadata/${uuid}${path ? '/file/p:' + path : ''}/${src_id ? '/id:' + src_id : ''}?versioning=full&related=(vwx,vgx,pdf,panorama,vgx_views)&fields=(asset.thumbnail_3d,asset.flags,asset.file_type,asset.label,asset.src_id,asset._.thumbnail_3d,asset._.flags,asset._.file_type,asset._.label,asset._.src_id)`
        });
    },
    createFolder (owner, folder) {
        return request({
            method: 'PUT',
            url: `${Settings.API_ROOT}${Settings.FOLDER_CREATE_URL}o:${owner}/p:${folder}/`
        });
    },
    rename (storageType, owner, path, new_name) {
        return request({
            url: `${Settings.API_ROOT}${storageType}/file/:rename/o:${owner}/p:${path}/`,
            method: 'PUT',
            data: {
                new_name
            },
            contentType: 'application/json; charset=utf-8'
        });
    },
    restore (asset) {
        const { storageType, owner, prefix, isFolder, srcId } = asset;
        const versionId = isFolder
            ? ''
            : (asset.isLatestVersion) ? '?versioning=on' : `v:${asset.versionId}/`;
        return request({
            url: `${baseUrl(storageType, owner, prefix, isFolder, srcId, null, 'restore')}${versionId}`,
            method: 'POST'
        });
    },
    getShallowFiles (paths, owner, storageType) {
        return request({
            url: `${Settings.API_ROOT}${storageType}/folder/:shallow/o:${owner}/`,
            method: 'PUT',
            data: {
                paths
            },
            contentType: 'application/json; charset=utf-8'
        });
    },
    updateVersionLabel (asset, label) {
        const { storageType, owner, prefix, srcId, versionId } = asset;
        return request({
            url: baseUrl(storageType, owner, prefix, false, srcId, versionId, 'label'),
            method: 'PATCH',
            data: { label },
            contentType: 'application/json; charset=utf-8'
        });
    },
    deleteVersion (asset, versionId) {
        const { storageType, owner, prefix, srcId } = asset;
        return request({
            url: baseUrl(storageType, owner, prefix, false, srcId, versionId),
            method: 'DELETE'
        });
    },
    mountSharedNode (asset, path = null) {
        const { storageType, ownerInfo, prefix } = asset;
        return request({
            url: `${Settings.API_ROOT}${storageType}/shared_with_me/folder/:mount/o:${ownerInfo.owner}/p:${prefix}/`,
            method: 'PUT',
            data: {
                action: 'mount',
                ...(path ? { path } : {})
            }
        });
    },
    unmountSharedNode (asset, action) {
        const { storageType, ownerInfo } = asset;
        const path = ownerInfo.mountPoint.path;
        return request({
            url: `${Settings.API_ROOT}${storageType}/shared_with_me/folder/:mount/o:${ownerInfo.owner}/p:${path}/`,
            method: 'PUT',
            data: { action: 'unmount' }
        });
    }
};

export default methods;
