import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toDataURL } from 'qrcode';

import { toast } from '../toast';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

function dataURItoBlob (dataURI) {
    const byteString = atob(dataURI.split(',')[1]);  // eslint-disable-line
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });  // eslint-disable-line
}

const QRDropdown = ({ id, url, togglerClass }) => {
    const [loading, setLoading] = useState(true);
    const [qrCodeImgUrl, setQrCodeImgUrl] = useState(null);

    useEffect(() => {
        setLoading(true);
        toDataURL(url, (err, url) => {
            setQrCodeImgUrl(url);
            setLoading(false);
        });
    }, [url]);

    const copyToClipboard = async () => {
        if (!window.ClipboardItem || !navigator.clipboard || !navigator.clipboard.write) {
            toast('COPY_UNSUPPORTED_BY_BROWSER');
            Settings.IN_WEB_VIEW && window.HostNotification.event('dialog_manage_links_copy_qr_not_supported_by_browser').send();
            return;
        }

        setLoading(true);
        return navigator.clipboard.write([
            new ClipboardItem({ 'image/png': dataURItoBlob(qrCodeImgUrl) })  // eslint-disable-line
        ])
            .then(() => {
                Settings.IN_WEB_VIEW && window.HostNotification.event('dialog_manage_links_copy_qr_clicked').send();
                toast('COPIED');
            })
            .catch(() => {
                toast('COPY_IMAGE_FAILED');
                Settings.IN_WEB_VIEW && window.HostNotification.event('dialog_manage_links_copy_qr_failed').send();
            })
            .finally(() => setLoading(false));
    };

    return (
        <PopupState variant='popper' popupId='qrCode'>
            {popupState => (
                <React.Fragment>
                    <IconButton
                        {...bindTrigger(popupState)}
                        className={`icon ${togglerClass || ''}`}
                        title={gettext('QR code')}
                        data-what='link-qr-dropdown'
                        ga-action='QR'
                        ga-label='Click_QR_Dropdown'
                    >
                        <Icon icon='qr-code-16' size='16px' />
                    </IconButton>
                    <Menu
                        {...bindMenu(popupState)}
                    >
                        <div
                            className='qr-wrap'
                            data-what='qr-code-popup'
                            id={`qr-code-img-${id}`}
                        >
                            <div className='svg-wrapper'>
                                <img src={qrCodeImgUrl} />
                            </div>
                            <Button
                                disabled={loading}
                                variant='secondary'
                                onClick={copyToClipboard}
                                data-what='copy-btn'
                                style={{ height: 24 }}
                            >{gettext('Copy')}</Button>
                        </div>
                    </Menu>
                </React.Fragment>
            )}</PopupState>
    );
};

QRDropdown.propTypes = {
    id: PropTypes.string,
    url: PropTypes.string,
    togglerClass: PropTypes.string
};

export default QRDropdown;
