import { sentAnalyticsEvent } from '../base/analytics';

export const forToolbar = (action, toolbarActionProps = {}) => {
    const { action: onClick, properties } = action;
    const { title, icon, GAAction, GALabel } = properties;

    const { toggled, divider, ...props } = toolbarActionProps;
    return {
        title,
        icon: icon.startsWith('icon-') ? icon.slice(5) : icon,
        toggled,
        divider,
        onClick: (event) => {
            onClick(event);
            sentAnalyticsEvent({ action: GAAction, label: GALabel }, { event });
        },
        props
    };
};

const prioritize = ({ actionsMap, hiddenActions = [] }) => {
    return Object.entries(actionsMap)
        .reduce((result, [key, action]) => {
            if (
                actionsMap[key].priority >= 0 &&
                !hiddenActions.includes(key)
            ) {
                action._key = key;
                result.push(action);
            }
            return result;
        }, [])
        .sort((a, b) => a.priority - b.priority);
};

export const parseActionMap = ({
    actionsMap,
    hiddenActions = [],
    doAction,
    createToolbarActionProps = () => ({})
}) => {
    return prioritize({ actionsMap, hiddenActions })
        .map(action => ({
            ...forToolbar(action, createToolbarActionProps(action)),
            onClick: doAction?.(action._key) || action.action
        }));
};
