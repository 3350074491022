import { pubsub, keyboard, Dialog } from '../base';
import { toast } from '../toast';
import { filePreview } from '../preview/file-preview.vm';
import { ItemCollection, asCollectionItemViewModel } from '../view/collection';
import toolbar from '../view/action-toolbar.vm';
import { AssetId } from '../asset/asset';

const CONTEXT_MENU_TEMPLATE = [
    ['view', 'download', 'rename', 'goToFolder', 'viewOriginal'],
    ['delete', 'deleteForever', 'restore'],
    ['share', 'stopSharing', 'manageLinks', 'mountSharedNode', 'unmountSharedNode'],
    ['exportPdf', 'generate3DModel', 'photogrammetry', 'styleTransfer', 'upsampling'],
    ['revitExport'],
    ['newTask', 'addToPresentation', 'copyToMyStorage']
];

const assetViewModelMixin = {
    open () {
        return this.isValid ? this.view([this]) : this.openInvalidAssetDialog();
    },
    view (group) {
        if (this.isValid) {
            if (this.isFolder) {
                this.routerCtx.router.update(this.viewUrl);
            } else {
                if (group) {
                    group = group.length > 1
                        ? group
                        : group[0].items.items().filter(asset => asset.isFile);
                }
                filePreview.openFromAsset(this, group);
                return false;
            }
        }
        return true;
    },
    // this method is called multiple times for some reason
    openInvalidAssetDialog () {
        return Dialog.open({
            component: 'alert',
            ctx: {
                ctx: { asset: this, flags: this.flags },
                template: {
                    name: this.isFolder ? 'dialog-invalid-folder' : 'dialog-invalid-file'
                }
            }
        });
    },
    contextMenuTemplate: CONTEXT_MENU_TEMPLATE
};

function createAssetViewModel (asset, assets, ctx) {
    const assetViewModel = asCollectionItemViewModel(asset, assets);
    Object.assign(assetViewModel, assetViewModelMixin);
    asset.assets = assets;
    asset.routerCtx = ctx.routerCtx;
    asset.viewUrl = assets.view.getAssetViewUrl(asset);
    ctx.select && asset.select();
    return asset;
};

const AssetCollection = ItemCollection;

const commonAssetViewMixin = {
    init (options) {
        this.name = options.name;
        this.routerCtx = options.ctx;
        this.toolbarActions = options.toolbarActions || {};
        this.service = options.service;
        this.assets = this.items = options.assets || new AssetCollection(ko.observableArray([]), this);
        const keyActions = options.assetKeyActions || {};
        this.keyEvents = keyboard.peekKeyEvents();
        this.keyEvents.extend(keyActions);

        const actionListeners = options.assetActionListeners || {};
        Object.keys(actionListeners).forEach(action => {
            pubsub.subscribe(this, `asset.action.${action}`, actionListeners[action]);
        });

        this.pushNotificationHandlers = options.pushNotificationHandlers || {};
        if (this.pushNotificationHandlers.folder) {
            const handlers = this.pushNotificationHandlers.folder = addAssetHandlerCheck(this.pushNotificationHandlers.folder);

            pubsub.subscribe(this, 'push-notification.v1.asset.folder', ({ event, data }) => {
                handlers[event] &&
                    handlers[event].call(this, event, AssetId.fromParams(data.asset, true), data);

                const message = {
                    delete: 'FOLDER_DELETE_COMPLETE',
                    restore: 'FOLDER_RESTORE_COMPLETE'
                }[event];
                message && toast(message, { count: 1 });
            });
        }

        if (this.pushNotificationHandlers.file) {
            const handlers = this.pushNotificationHandlers.file = addAssetHandlerCheck(this.pushNotificationHandlers.file);

            pubsub.subscribe(this, 'push-notification.v1.asset.file', ({ event, data }) => {
                handlers[event] &&
                    handlers[event].call(this, event, AssetId.fromParams(data.asset), data);
            });
        }

        this.routerCtx.addBeforeNavigateCallback(() => {
            pubsub.unsubscribeAll(this);
            this.keyEvents.reduce();
            this.assets.cleanup();
            Dialog.dismiss();
        });
        this.columns = options.columns || [];
        this.initSorting();
        this.load();
        toolbar.view(this);
    },
    handleUpdateAction (updated) {
        updated = updated.map(asset => createAssetViewModel(
            asset, this.assets, { routerCtx: this.routerCtx }
        ));
        updated.forEach(asset => asset.select());
        this.assets.update(updated);
    }

};

function addAssetHandlerCheck (handlers) {
    return Object.keys(handlers)
        .reduce((acc, eventName) => {
            acc[eventName] = function (event, assetId, data) {
                this.shouldHandleAsset(assetId) &&
                    handlers[eventName].call(this, event, assetId, data);
            };
            return acc;
        }, {});
}

export {
    assetViewModelMixin,
    commonAssetViewMixin,
    createAssetViewModel,
    AssetCollection
};
