import ReactDOM18 from '../utils/ReactMigrator';
import React from 'react';

import { Toasts } from '@vectorworks/vcs-ui/dist/lib/Toast/Toast';

try {
    ReactDOM18.render(
        <Toasts />,
        document.getElementById('react-toasts')
    );
} catch (e) {
    const isJest = typeof process !== 'undefined' && process.env.JEST_WORKER_ID !== undefined;
    !isJest && console.log('Unable to render toasts.');
}
